import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeroUnit from "../components/hero-unit/hero-unit"
import OverviewSection from "../components/overview-section/overview-section"
import ContentSection from "../components/content-section/content-section"
import PortfolioSection from "../components/portfolio-section/portfolio-section"
import ProcessSection from "../components/process-section/process-section"
import WorkedWithSection from "../components/worked-with/worked-with"
import AboutSection from "../components/about-section/about-section"
import TestimonialsSection from "../components/testimonials-section/testimonials-section"
import RecentArticles from "../components/recent-articles/recent-articles"
import HireUsBanner from "../components/hire-us-banner/hire-us-banner"

import { useArticles } from "../hooks/use-articles"
import { recentPosts } from "../utils/utils"

import {
  homePage,
  withOverview,
} from "../components/hero-unit/hero-unit.module.scss"

import "./home.scss"

const getImage = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero-unit-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const IndexPage = () => {
  const { heroImage } = useStaticQuery(getImage)
  const posts = recentPosts(useArticles())

  return (
    <LayoutMain className={`${homePage} home-page`}>
      <SeoHeaders title="Home" keywords={[`portfolio`, `web developer`]} />
      <HeroUnit
        class={withOverview}
        heroImage={heroImage}
        pageTitle="Digital Marketing Agency"
      >
        <p>
          Internet publishing allows the business owner to create a website for
          his or her business or books and publish it on a number of different
          sites.
        </p>
        <p>
          Another benefit to online publishing is the opportunity to reach out
          to a much larger audience. Most entrepreneurs have a much wider
          readership because they publish material from all over the world. This
          is a benefit to anyone who is considering trying to start their own
          business through the internet.
        </p>
      </HeroUnit>
      <section className="section-overview">
        <OverviewSection />
      </section>
      <section className="section-services">
        <ContentSection
          heading="Lead Generation"
          buttonText="Learn More"
          target="/lead-generation"
          testid="section-lead-generation"
        >
          <p>
            Lead generation has long been a key part of the online sales funnel
            for most B2B businesses as their sales pages may cost thousands of
            dollars to produce and less likely to be purchased directly by their
            target audience. As an effective way to generate traffic, lead
            generation must be done efficiently through the use of email
            marketing tools and other methods that work with the content of your
            website. This will create a more personalized experience for your
            potential customers and give you more opportunities to convert into
            sales.
          </p>
        </ContentSection>
        <ContentSection
          heading="Web Presence"
          buttonText="Learn More"
          target="/web-presence"
          testid="section-web-presence"
        >
          <p>
            What is web presence? How we do it? The first part of having web
            presence is we need to understand what web presence is and what it
            does for us. It is our profile that is displayed to the search
            engines, and it's a way for people to know who we are. The second
            part of web presence is the information people get from it. It gives
            a reason to be interested in what we are offering and how people
            find us when they do a search on the internet.
          </p>
        </ContentSection>
        <ContentSection
          heading="Local Marketing"
          buttonText="Learn More"
          target="/local-marketing"
          testid="section-local-marketing"
        >
          <p>
            Local marketing also called "neighborhood advertising" or "area
            marketing", is a successful marketing strategy that targets
            customers/clients within a certain geographic area with different
            marketing messages tailored to specific demographics within a
            certain locality. This type of marketing is very important for any
            small business considering their potential customers are located
            within a certain geographical area and not necessarily within the
            city/state they live in.
          </p>
        </ContentSection>
      </section>
      <section>
        <WorkedWithSection />
      </section>
      {process.env.GATSBY_ACTIVE_ENV === "development" && (
        <section>
          <TestimonialsSection />
        </section>
      )}
      {process.env.GATSBY_ACTIVE_ENV === "development" && (
        <section>
          <PortfolioSection />
        </section>
      )}
      <section>
        <ProcessSection />
      </section>
      <section>
        <AboutSection />
      </section>
      <section>
        <RecentArticles recentPosts={posts} />
      </section>
      <section>
        <HireUsBanner />
      </section>
    </LayoutMain>
  )
}

export default IndexPage
