import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import CircleBlock from "../circle-block/circle-block"
import SocialIcon from "../../images/icon-social.svg"

import { overviewSection, overviewItem } from "./overview-section.module.scss"

const getImages = graphql`
  query {
    designImage: file(relativePath: { eq: "web-design-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const OverviewSection = () => {
  const { designImage } = useStaticQuery(getImages)

  return (
    <div data-testid="overview-section" className={overviewSection}>
      <div className={overviewItem}>
        <CircleBlock
          iconImg={designImage}
          icon={<SocialIcon />}
          target="/service/email-marketing"
        >
          <h3>Powerful Email Marketing</h3>
          <p>Send great-sounding emails so you can bond with your prospects</p>
        </CircleBlock>
      </div>
      <div className={overviewItem}>
        <CircleBlock
          iconImg={designImage}
          icon={<SocialIcon />}
          target="/web-presence"
        >
          <h3>High Converting Websites</h3>
          <p>
            Sales generating sites and landing pages that increase lead
            generation
          </p>
        </CircleBlock>
      </div>
      <div className={overviewItem}>
        <CircleBlock
          iconImg={designImage}
          icon={<SocialIcon />}
          target="/service/e-book-creation"
        >
          <h3>Author Building Ebook Creation</h3>
          <p>
            Tell your Story and build more awareness to create a buzz about your
            business
          </p>
        </CircleBlock>
      </div>
    </div>
  )
}

export default OverviewSection
