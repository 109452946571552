import React from "react"
import Img from "gatsby-image"

import { headingImage, imageWrapper } from "./heading-image.module.scss"

const HeadingImage = ({ heading, image, caption }) => {
  return (
    <div className={headingImage}>
      <h3>{heading}</h3>
      <div className={imageWrapper}>
        <Img fluid={image} alt={caption} />
      </div>
    </div>
  )
}

export default HeadingImage
