import React from "react"

import ProcessWalkthrough from "../process-walkthrough/process-walkthrough"
import SectionHeadings from "../section-headings/section-headings"

import './process-section.scss';

const ProcessSection = () => {
  return (
    <div className="section-our-process">
      <SectionHeadings
        subheading="Our Proprietary 5 Step Website Development System"
        mainHeading="We use our simple to follow 5 Step Website Creation Process developed in house by our Team of Online Specialists"
      />
      <ProcessWalkthrough />
    </div>
  )
}

export default ProcessSection
