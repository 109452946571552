import React from "react"
import ButtonItem from "../button-item/button-item"

const ProcessContent = ({ process: { title, description }, active }) => (
  <div className={`process-description ${active ? "active" : ""}`}>
    <h4 className="process-description-heading">{title}</h4>
    <div className="process-description-content">
        {description.split("\n").map((item, key) => {
          return (
            <p key={key}>
              {item}
            </p>
          )
        })}
    </div>
    <div
      className="process-description-buttonholder" 
      data-testid="process-description-button-holder"
    >
      <ButtonItem isPill={true} isLight={true} target="/process">Get Started</ButtonItem>
    </div>
  </div>
)

export default ProcessContent
