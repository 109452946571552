import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import './worked-with.scss'

const WorkedWithSection = () => {
  const data = useStaticQuery(graphql`
    query {
      usaaLogo: file(relativePath: { eq: "logo-usaa.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sawsLogo: file(relativePath: { eq: "logo-saws.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      livingstonLogo: file(relativePath: { eq: "logo-livingston-large.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      kaplanLogo: file(relativePath: { eq: "logo-kaplan.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      thiessanLogo: file(relativePath: { eq: "logo-thiessan-law-firm.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      whiteheadLogo: file(relativePath: { eq: "logo-marc-whitehead.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  
  return (
    <div className="section-worked-with">
      <h2 className="main-heading elr-text-center">Who We've Worked With</h2>
      <ul className="client-list">
        <li>
          <Img alt="Livingston Plastic Surgery" fixed={data.livingstonLogo.childImageSharp.fixed} />
        </li>
        <li>
          <Img alt="Marc Whitehead and Associates" fixed={data.whiteheadLogo.childImageSharp.fixed} />
        </li>
        <li>
          <Img alt="USAA" fixed={data.usaaLogo.childImageSharp.fixed} />
        </li>
        <li>
          <Img alt="San Antonio Water System" fixed={data.sawsLogo.childImageSharp.fixed} />
        </li>
        <li>
          <Img alt="Thiessan Law Firm" fixed={data.thiessanLogo.childImageSharp.fixed} />
        </li>
        <li>
          <Img alt="Kaplan Sinus Relief" fixed={data.kaplanLogo.childImageSharp.fixed} />
        </li>
      </ul>
    </div>
  )
}

export default WorkedWithSection
