import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HeadingImage from "../heading-image/heading-image"
import ButtonItem from "../button-item/button-item"

import { sectionAbout, aboutContent } from "./about-section.module.scss"

const getImage = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AboutSection = () => {
  const { aboutImage } = useStaticQuery(getImage)

  return (
    <div data-testid="section-about" className={sectionAbout}>
      <div className={aboutContent}>
        <h2>About Wildfire Marketing</h2>
        <p>
          We create and publish Online Marketing Assets in Digital Marketing. My
          Name is Elizabeth Rogers, and I am a Programmer in the Web Development
          space. Over the last 10 years, I have been helping Business Owners
          build their Websites using a savvy approach to the website creation
          process that few in the Web Development world have access to.
        </p>
        <ButtonItem target="/about" isGhost={true} isPill={true}>
          About Us
        </ButtonItem>
      </div>
      {aboutImage && (
        <HeadingImage
          heading="Who We Are"
          image={aboutImage?.childImageSharp?.fluid}
          caption="An Image"
        />
      )}
    </div>
  )
}

export default AboutSection
