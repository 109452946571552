import React from "react"

const ProcessNavItem = ({ handleClick, id, active }) => (
  <li>
    <button
      onClick={() => handleClick(id)}
      className={`"nav-item-button" ${active ? "active" : ""}`}
      data-testid={`process-nav-item-button-${id}`}
    >
      <span className="navitem-button-text">{id}</span>
    </button>
  </li>
)

export default ProcessNavItem
