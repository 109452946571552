import React, { useState } from "react"
import ProcessContent from "../process-content/process-content"
import ProcessNavItem from "../process-nav-item/process-nav-item"

import "./process-walkthrough.scss"

const ProcessWalkthrough = () => {
  const [current, setCurrent] = useState(1)
  const processList = [
    {
      id: 1,
      title: "Initial Evaluation and Questionnaire",
      description: `We learn about your business and your digital marketing needs though a questionnaire and a short conversation`,
    },
    {
      id: 2,
      title: "Content and Website Planning",
      description: `We will develop a plan for your new website and create content for each page.`,
    },
    {
      id: 3,
      title: "Branding and Design",
      description: `Develop your digital branding and design your new website. We will create a unique design.`,
    },
    {
      id: 4,
      title: "Development and Launch",
      description: `Build out your website based on the design and content from the previous steps.`,
    },
    {
      id: 5,
      title: "Maintenance and Updates",
      description: `Ensure that your website is always working properly and adding fresh new content.`,
    },
  ]

  const changeSlide = index => {
    setCurrent(index)
  }

  return (
    <div className="process-walkthrough">
      <nav className="process-walkthrough-nav">
        <ul className="process-walkthrough-list">
          {processList.map(process => (
            <ProcessNavItem
              key={process.id}
              id={process.id}
              handleClick={changeSlide}
              active={current === process.id}
            />
          ))}
        </ul>
      </nav>
      <div className="process-content-holder">
        {processList.map(process => (
          <ProcessContent
            active={process.id === current}
            process={process}
            key={process.id}
          />
        ))}
      </div>
    </div>
  )
}

export default ProcessWalkthrough
